.wrap-input-text {
  margin-bottom: 16px; }

.wrap-input-text label {
  color: #156992;
  display: block;
  font-family: "RobotoCondensed";
  font-size: 1em;
  margin-bottom: 8px;
  text-transform: uppercase;
  float: left; }

.wrap-input-text input[type=text]::placeholder,
.wrap-input-text input[type=password]::placeholder {
  color: #96a0a7; }

.wrap-input-text input[type=text],
.wrap-input-text input[type=password] {
  border: none;
  background-color: #ebebeb;
  color: #3b4e5c;
  padding: 16px;
  font-family: "RobotoCondensed";
  width: 100%; }
