input[type=text], select {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box; }

input[type=submit] {
  width: 100%;
  background-color: #4CAF50;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer; }

input[type=submit]:hover {
  background-color: #45a049; }

div.container {
  border-radius: 5px;
  background-color: #f2f2f2;
  padding: 20px;
  margin: 20px;
  -webkit-display: -webkit-box;
  -webkit-display: -webkit-flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start; }

div.container2 {
  border-radius: 5px;
  background-color: #d7d7d7 !important;
  padding: 10px;
  -webkit-display: -webkit-box;
  -webkit-display: -webkit-flex;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start; }

div.column {
  flex-grow: 1;
  margin-top: 15px; }

.break {
  flex-basis: 100%;
  height: 0; }

div.column ul li.item {
  display: inline;
  padding: 10px; }

.wrap-input-text {
  margin: 0 0 0px !important; }

.wrap-input-text input {
  background-color: white !important; }

.wrap-input-text label {
  margin-bottom: 0px !important;
  font-family: "RobotoCondensed"; }

.ag-header-cell-text {
  font-size: 16px !important;
  color: #070707 !important; }

.ag-theme-material .ag-checkbox-input-wrapper.ag-checked::after {
  color: #156992 !important; }

.MuiSelect-select.MuiSelect-select {
  background-color: white; }

div.column {
  flex-grow: 1;
  margin-top: 0px; }

.dialog-header-title {
  font-size: 23px;
  letter-spacing: -0.6px;
  color: #ff0000;
  font-weight: 700; }

.btn-alert {
  font-size: 16px; }

.icon-warning::before {
  content: '\e81c';
  color: red;
  font-weight: 700; }
