.dropdown-content {
  display: none;
  position: absolute;
  background-color: #005091;
  font-size: 14px;
  min-width: 260px;
  z-index: 1;
  top: 88%;
  left: -10%;
  list-style: none; }
  .dropdown-content .menu-item {
    text-decoration: none;
    border-bottom: 1px solid #fff;
    text-transform: uppercase; }
    .dropdown-content .menu-item a {
      color: #fff;
      padding: 16px 16px;
      text-decoration: none;
      display: block; }
    .dropdown-content .menu-item:last-child {
      border-bottom: 0; }
  .dropdown-content .menu-chamfer {
    position: relative;
    margin-left: 67px;
    top: -10px; }
    .dropdown-content .menu-chamfer .menu-border {
      position: absolute;
      border-right: 14px solid transparent;
      border-left: 14px solid transparent;
      border-bottom: 11px solid #005091; }

.dropdow-title {
  color: #156992;
  padding: 16px;
  border: none;
  cursor: pointer; }
  .dropdow-title .text {
    font-size: 20px;
    margin-left: 5px; }

.dropdown {
  display: inline-block;
  font-family: "RobotoCondensed";
  position: relative; }
  .dropdown i {
    position: relative;
    top: -2px; }
  .dropdown:hover .dropdown-content {
    display: block; }
