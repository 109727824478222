.wrap-input-text {
  margin-bottom: 16px; }

.wrap-input-text label {
  color: #156992;
  display: block;
  font-family: "RobotoCondensed";
  font-size: 1em;
  margin-bottom: 8px;
  text-transform: uppercase;
  float: left; }

.wrap-input-text textarea {
  border: none;
  background-color: #ebebeb;
  color: #3b4e5c;
  padding: 16px;
  font-family: "RobotoCondensed";
  width: 100%; }

.wrap-input-text textarea {
  background-color: white;
  height: 88px;
  border-radius: 2px;
  resize: none; }

.wrap-input-text .counter {
  font-size: 12px;
  font-weight: 300;
  font-style: normal;
  font-stretch: condensed;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  text-transform: none;
  float: right;
  color: #5b788e; }
  .wrap-input-text .counter strong {
    margin: 0;
    padding: 0;
    font-weight: bold; }
