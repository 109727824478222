.btn {
  text-align: center; }

.none {
  display: none; }

.block {
  display: block; }

.dialog-content {
  min-width: 520px; }

.legenda {
  padding: 20px; }

#customize-categories {
  display: flex; }

#categories-tree {
  flex: 1;
  margin-right: 29px;
  display: grid; }

#title {
  text-transform: uppercase;
  color: #148cc7;
  font-size: 16px;
  font-family: "RobotoCondensed";
  font-weight: bold;
  margin-bottom: 8px; }

#description {
  font-family: "RobotoCondensed";
  font-size: 16px;
  font-weight: 300;
  color: #5b788e;
  margin-bottom: 10px; }

ul > li > div > div:first-child {
  cursor: pointer;
  display: inline-block;
  padding: 2px 3px; }
  ul > li > div > div:first-child svg {
    fill: #2f9bd4; }

#tree {
  height: 318px;
  overflow: auto; }
  #tree * {
    color: #2f9bd4;
    font-weight: bold; }
  #tree ul > li > div {
    display: flex; }
  #tree #tree-item {
    cursor: pointer;
    display: flex;
    white-space: nowrap; }
  #tree .partnerCustom {
    color: #eb2929; }
  #tree .planCustom {
    color: #ff9d00; }

#legenda {
  overflow: auto; }
  #legenda * {
    color: #2f9bd4;
    font-weight: bold; }
  #legenda .partnerCustom {
    color: #eb2929; }
  #legenda .planCustom {
    color: #ff9d00; }

#selected-items {
  flex: 1; }
  #selected-items #title {
    color: #2f9bd4; }

#selected-categories {
  height: 318px;
  overflow: auto; }

.header-message-container {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 10; }

.header-message {
  color: #fff;
  opacity: 0.98;
  padding: 34px 20px 34px 48px; }
  .header-message i {
    color: #fff; }
    .header-message i::before {
      position: relative;
      top: 1px; }

.header-success-message,
.header-error-message,
.header-confirm-message {
  font-family: "RobotoCondensed";
  font-size: 24px;
  font-stretch: condensed;
  font-style: italic; }
  .header-success-message .icon-ok-check,
  .header-success-message .icon-warning,
  .header-error-message .icon-ok-check,
  .header-error-message .icon-warning,
  .header-confirm-message .icon-ok-check,
  .header-confirm-message .icon-warning {
    font-size: 26px;
    margin-right: 22px; }

.header-success-message {
  background-color: #009161; }

.header-error-message {
  background-color: #d8232a; }

.separator {
  height: 1px;
  opacity: 0.1;
  background-color: #5b788e; }

.custom-category-item {
  list-style: none;
  padding: 10px 8px 12px 8px;
  display: flex; }
  .custom-category-item .custom-category-title {
    padding: 8px 0 8px 0;
    flex: 1; }
    .custom-category-item .custom-category-title span {
      width: 10px;
      font-family: "RobotoCondensed";
      font-size: 14px;
      font-weight: 700;
      color: #156992; }
  .custom-category-item .custom-category-actions {
    flex: 1;
    position: relative; }
    .custom-category-item .custom-category-actions .icon-percentage {
      position: absolute;
      right: 0; }
    .custom-category-item .custom-category-actions i {
      padding: 10px 0 10px 8px;
      width: 12px;
      height: 12px;
      color: #c20000;
      font-weight: bold; }
    .custom-category-item .custom-category-actions .wrap-input-text {
      margin: 0;
      width: 82px;
      border-radius: 2px; }
      .custom-category-item .custom-category-actions .wrap-input-text label {
        display: none; }
      .custom-category-item .custom-category-actions .wrap-input-text input {
        padding: 8px 10px; }
      .custom-category-item .custom-category-actions .wrap-input-text .input-box {
        height: 32px; }
      .custom-category-item .custom-category-actions .wrap-input-text .percentage-icon {
        line-height: 32px; }
