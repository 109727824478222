input[type=text], select {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

input[type=submit] {
    width: 100%;
    background-color: #4CAF50;
    color: white;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

input[type=submit]:hover {
    background-color: #45a049;
}

div.container {
    border-radius: 5px;
    background-color: #f2f2f2;
    padding: 20px;
    margin: 20px;
    -webkit-display: -webkit-box;
    -webkit-display: -webkit-flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
}

div.column {
    flex-grow: 1;
    margin-top: 15px;
}

.break {
    flex-basis: 100%;
    height: 0;
}

div.column ul li.item { 
    display: inline; 
    padding: 10px;
}

.react-datepicker__input-container {
    width: inherit;
}
  
.react-datepicker-wrapper {
    width: 130px;
}

.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
    padding-right: 39px;
    background-color: white;
}