.chart-description-dialog-title {
  font-size: 14px;
  font-weight: bold;
  line-height: 1.5;
  padding-right: 12px; }

.chart-description-dialog-key {
  font-weight: bold; }

.chart-id {
  margin-left: 7px; }

.seller {
  margin-left: 33px; }
