.wrap-input-text {
  margin: 0 auto 38px; }

.wrap-input-text input {
  background-color: white !important; }

.wrap-input-text label {
  margin-bottom: 38px;
  font-family: "RobotoCondensed"; }

.css-19kzrtu {
  padding: 0px !important; }

.MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.Mui-selected {
  font-weight: 700 !important;
  background-color: #d7d7d7 !important; }

.MuiButtonBase-root.MuiTab-root {
  background-color: #e9e9e9 !important;
  border-top-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
  margin-right: 1px !important;
  height: 60px !important;
  font-size: 0.75rem !important;
  padding: 12px 10px; }

div.column {
  font-weight: 100; }

.column > span {
  font-weight: 700; }
