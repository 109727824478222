.wrap-input-text {
  margin-bottom: 16px;
  position: relative; }
  .wrap-input-text label {
    width: 100%; }
  .wrap-input-text .wrap-input-label {
    color: #156992;
    display: inline-block;
    font-size: 1em;
    margin-bottom: 8px; }
  .wrap-input-text .wrap-input-message {
    display: none;
    font-size: 0.895em;
    position: relative;
    text-transform: initial;
    top: -1px;
    margin-left: 2px;
    font-weight: 300; }
  .wrap-input-text input[type=text]::placeholder,
  .wrap-input-text input[type=password]::placeholder {
    color: #96a0a7; }
  .wrap-input-text input[type=text],
  .wrap-input-text input[type=password] {
    border: none;
    border-radius: 3px;
    background-color: #f7f7f7;
    color: #3b4e5c;
    padding: 16px;
    font-family: "RobotoCondensed";
    width: 100%; }
    .wrap-input-text input[type=text].with-icon,
    .wrap-input-text input[type=password].with-icon {
      padding-left: 40px; }
  .wrap-input-text.error-text .wrap-input-message {
    display: inline-block; }
  .wrap-input-text.error .wrap-input-label,
  .wrap-input-text.error .wrap-input-message {
    color: #d8232a; }
  .wrap-input-text.error input[type=text],
  .wrap-input-text.error input[type=password] {
    background-color: rgba(216, 35, 42, 0.15); }
    .wrap-input-text.error input[type=text]::placeholder,
    .wrap-input-text.error input[type=password]::placeholder {
      color: rgba(216, 35, 42, 0.6); }
  .wrap-input-text i {
    font-size: 15px;
    position: absolute;
    left: 17px;
    top: 16px; }
  .wrap-input-text .mandatory-label {
    text-transform: capitalize;
    color: #d5d4d4; }
  .wrap-input-text::after {
    content: " ";
    clear: both;
    display: table; }
