.login-background {
  background-color: #005091;
  margin: 0;
  height: 100vh;
  overflow: hidden; }
  .login-background .wrap-login {
    background-color: #fff;
    box-shadow: 0 3px 10px 0 #464e54;
    margin: 0 auto;
    padding: 48px 44px 40px;
    width: 408px;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); }
    .login-background .wrap-login .logo {
      display: block; }
    .login-background .wrap-login .logo,
    .login-background .wrap-login .wrap-input-text {
      margin: 0 auto 38px; }
    .login-background .wrap-login .wrap-input-text label {
      margin-bottom: 38px;
      font-family: "RobotoCondensed"; }
    .login-background .wrap-login .btn-login {
      display: block;
      margin: 0 auto;
      min-width: 160px; }
  .login-background .invalid-user-password {
    font-family: "RobotoCondensed";
    font-weight: 500;
    color: #d8232a;
    margin: 0 auto 31px;
    display: block; }
    .login-background .invalid-user-password .message {
      margin: 0 auto;
      width: 90%; }
      .login-background .invalid-user-password .message .icon-warning {
        font-size: 150%; }
      .login-background .invalid-user-password .message span {
        display: inline-block;
        text-transform: uppercase;
        margin-left: 10px; }

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  background-color: rgba(0, 80, 145, 0.8); }

.googleButton {
  cursor: pointer;
  display: block;
  font-size: 1.3em;
  box-sizing: content-box;
  margin: 20px auto 0px;
  width: 100%;
  padding: 15px 20px;
  border-radius: 24px;
  border-color: transparent;
  background-color: white;
  box-shadow: 0px 16px 60px rgba(78, 79, 114, 0.08);
  position: relative; }

.googleButtonText {
  color: #4285f4;
  font-weight: bolder; }

.googleIcon {
  height: 25px;
  width: 25px;
  margin-right: 0px;
  position: absolute;
  left: 30px;
  align-items: center; }
